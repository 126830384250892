import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : {};

// init state
const initState = {
  userInfo: userInfoFromStorage,
 
};

// create context
export const GlobalContext = createContext(initState);

// provider

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initState);

  function setUserInfo(data) {
    dispatch({
      type: "SET_USER_INFO",
      payload: data,
    });
  }
  
  return (
    <GlobalContext.Provider
      value={{
        userInfo: state.userInfo,
     
        setUserInfo,
      
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
