import styled from "styled-components";

export const LanguageSelectorWrapper = styled.div`
  width: 65px;
  height: 102px;
  border: 1px solid #88c1a3;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 102px;
  }
`;

export const LanguageOption = styled.div`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  width: 100%;
  height: calc(100% / 2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: "#1B4965";
  &:hover {
    background-color: #1b4965;
    color: white;
    box-sizing: border-box;
  }
  &:first-child {
    &:after {
      content: "";
      background: rgba(27, 73, 101, 0.3);
      position: absolute;
      bottom: 0;
      left: calc((100% - 75%) / 2);
      height: 1px;
      width: 75%;
    }
  }
  transition: all 0.2s ease;
`;

export const LanguageInput = styled.input`
  position: fixed;
  opacity: 0;
  pointer-events: none;
`;

export const LanguageLabel = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;

export const Flag = styled.img`
  width: 20px;
  height: 20px;
  margin-left: -10%;
`;
