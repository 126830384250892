import React, { useState } from "react";
import { TextLabel, Text } from "../textStyles/style";
import {
  SelectWrapper,
  LabelWrapper,
  ArrowDown,
  OptionsContainer,
  OptionItem,
  DropDownWrapper,
} from "./style";

const DropDown = ({ toggle, setToggle, title, options, value, setValue }) => {
  const changeHandler = (v) => {
    setValue(v);
    setToggle(false);
  };
  return (
    <DropDownWrapper>
      <SelectWrapper toggle={toggle ? 1 : 0} onClick={() => setToggle(!toggle)}>
        <LabelWrapper>
          <TextLabel>{title}</TextLabel>
        </LabelWrapper>

        <Text style={{ paddingLeft: "5%" }}>{value.label}</Text>
        <ArrowDown toggle={toggle ? 1 : 0} />
      </SelectWrapper>

      <OptionsContainer toggle={toggle ? 1 : 0}>
        {options.map((option) => {
          return (
            <OptionItem
              onClick={() => changeHandler(option)}
              selected={value.value === option.value}
              key={option.value}
            >
              <Text>{option.label}</Text>
            </OptionItem>
          );
        })}
      </OptionsContainer>
    </DropDownWrapper>
  );
};

export default DropDown;
