import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import Mascotte from "../../images/main/mascotte.svg";
import { H5, Text, H4 } from "../textStyles/style";
import "./video-style.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Box, Button } from "@mui/material";
export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const MySwal = withReactContent(Swal);
    const { src, onReady, type } = props;
    const options = {
        plugins: {
            httpSourceSelector: {
                default: "auto",
            },
        },
        autoplay: false,
        controls: true,
        contextMenu: false,
        sources: [
            {
                src: src,
                type: type,
                // src: "https://dev.massatrails.net/video/playlist-audio/sample.mp3",
                // type: "audio/mp3",
                // src : "https://dev.massatrails.net/video/playlist-MASSA_TRAILER_FR_HD2/master.m3u8",
                // type : "application/x-mpegURL"
            },
        ],
        preload: "auto",
        fluid: true,
        html5: {
            hls: {
                overrideNative: true,
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false,
            useBandwidthFromLocalStorage: true,
        },
        controlBar: {
            pictureInPictureToggle: false,
        },
        poster: "../../images/main/MASSA_logo_blanc.png",
    };
    React.useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = (playerRef.current = videojs(
                videoElement,
                options,
                () => {
                    console.log("player is ready");
                    onReady && onReady(player);
                }
            ));

            player.httpSourceSelector();
            player.poster(
                "https://dev.massatrails.net/media/img/MASSA_logo_blanc.png"
            );
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef, onReady]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;
        player.fill(true);
        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                borderRadius: "inherit",
                objectFit: "cover",
            }}
        >
            <video
                onContextMenu={(e) => e.preventDefault()}
                ref={videoRef}
                className="video-js vjs-theme-forest"
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                }}
            />
        </div>
    );
};

export default VideoJS;
