// This file contains the language selection menu that
// pops up when clicking on language selection icon
// This file was last modified on 2022-01-03 19:07:32
// Author : Mohamed Amine Dhiab © Massa Trails
import {
  Flag,
  LanguageInput,
  LanguageLabel,
  LanguageOption,
  LanguageSelectorWrapper,
} from "./style";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FrFlag from "../../images/main/france_round_flag.webp";
import UsaFlag from "../../images/main/usa_round_flag.webp";
//this language selector component can be wrapped inside a div , it takes a setActive method as prop which corresponds to switching between showing up and hiding the wrapper of the selector
const LanguageSelector = ({ setActive }) => {
  //initializing i18n
  const i18n = useTranslation()[1];
  // this function is called whenever a language label has been selected
  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  const history = useHistory();
  return (
    <LanguageSelectorWrapper onChange={changeLanguage}>
      <LanguageOption
        onClick={() => {
          setActive(false);
          history.go(0);
        }}
      >
        <LanguageInput type="radio" value="fr" name="language" id="fr-lang" />
        <LanguageLabel htmlFor="fr-lang">
          Fr <Flag src={FrFlag} alt="image" />{" "}
        </LanguageLabel>
      </LanguageOption>
      <LanguageOption
        onClick={() => {
          setActive(false);
          history.go(0);
        }}
        value="en"
      >
        <LanguageInput type="radio" value="en" name="language" id="en-lang" />
        <LanguageLabel htmlFor="en-lang">
          En <Flag src={UsaFlag} alt="image" />
        </LanguageLabel>
      </LanguageOption>
    </LanguageSelectorWrapper>
  );
};

export default LanguageSelector;
