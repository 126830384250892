import React, { useContext, useState } from "react";
import { H6, SmallBoldText, SmallText } from "../textStyles/style";
import { Link } from "react-router-dom";
import {
  RollContainer,
  IconsWrapper,
  LikeIconRender,
  HeartIconRender,
  ContentWrapper,
  LikeIconContainer,
  FavoriteIconContainer,
  PlayIconRender,
  PlayIconContainer,
  SeasonDurationCotnainer,
} from "./style";
import axios from "axios";
import { NotAvailablePopOpener } from "../CustomHooks";
import { GlobalContext } from "../../context/GlobalState";
import { useCookies } from "react-cookie";

const RollOver = ({ Type, item }) => {
  const { userInfo, setUserInfo } = useContext(GlobalContext);

  const cookies = useCookies()[0];
  const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
  const token = process.env.REACT_APP_TOKEN || cookies[cookieName];
  const likedTrails = userInfo.liked_trails || [];
  const likedWorkshops = userInfo.liked_workshops || [];
  const liked = [...likedTrails, ...likedWorkshops];
  const checkIfLiked = liked.includes(item.ressourceCode);
  const checkIfFavorite = userInfo.favourite.includes(item.ressourceCode);

  const [isLiked, setIsLiked] = useState(checkIfLiked);
  const [isFavorite, setIsFavorite] = useState(checkIfFavorite);

  const checkIfNotAvailable = () => {
    if (item.metadata.seasonsTotal === 0) {
      NotAvailablePopOpener();
    }
  };

  const likeHandler = () => {
    setIsLiked(!isLiked);
    axios
      .post(process.env.REACT_APP_LIKED_ENDPOINT, {
        access_token: token,
        ressourceCode: item.ressourceCode,
      })
      .then(() => {
        axios
          .post(process.env.REACT_APP_USER_INFO_ENDPOINT, {
            access_token: token,
          })
          .then((res) => {
            setUserInfo(res.data);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
          });
      });
  };
  const favoriteHandler = () => {
    setIsFavorite(!isFavorite);
    axios
      .post(process.env.REACT_APP_FAVORITE_ENDPOINT, {
        access_token: token,
        ressourceCode: item.ressourceCode,
      })
      .then(() => {
        axios
          .post(process.env.REACT_APP_USER_INFO_ENDPOINT, {
            access_token: token,
            ressourceCode: item.ressourceCode,
          })
          .then((res) => {
            setUserInfo(res.data);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
          });
      });
  };

  return (
    <RollContainer>
      <IconsWrapper>
        <Link
          onClick={checkIfNotAvailable}
          to={
            item.metadata.seasonsTotal === 0
              ? ""
              : Type === "Trail"
              ? "/trail/" + item.ressourceCode
              : "/workshop/" + item.ressourceCode
          }
        >
          <PlayIconContainer>
            <PlayIconRender />
          </PlayIconContainer>
        </Link>
        <LikeIconContainer onClick={likeHandler} isliked={isLiked ? 1 : 0}>
          <LikeIconRender isliked={isLiked ? 1 : 0} />
        </LikeIconContainer>
        <FavoriteIconContainer
          onClick={favoriteHandler}
          isfavorite={isFavorite ? 1 : 0}
        >
          <HeartIconRender isfavorite={isFavorite ? 1 : 0} />
        </FavoriteIconContainer>
      </IconsWrapper>
      <ContentWrapper>
        <H6>{item.metadata.introduction}</H6>

        <SmallText>{item.metadata.description}</SmallText>

        <SeasonDurationCotnainer>
          {Type === "Trail" && (
            <SmallBoldText>
              {item.metadata.seasonsTotal > 0
                ? item.metadata.seasonsTotal +
                  ` saison${item.metadata.seasonsTotal > 1 ? "s" : ""}`
                : ""}
            </SmallBoldText>
          )}
          {/* {item.metadata.seasonsTotal > 0 && (
            <SmallBoldText>50 min</SmallBoldText>
          )} */}
        </SeasonDurationCotnainer>
      </ContentWrapper>
    </RollContainer>
  );
};

export default RollOver;
