// Styled components for the header section of the page "Accueil non connecté" and others...

import { Link } from "react-router-dom";
import styled from "styled-components";
import { BsCaretDownFill } from "react-icons/bs";
import { ReactComponent as AvatarIcon } from "../../images/main/avatar.svg";

export const HeaderSection = styled.div`
    height: clamp(54px, 8vw, 84px);
    width: 100%;
    display: flex;
    position: fixed;
    white-space: nowrap;
    left: 0;
    top: 0;
    background: #fbf6f3;
    flex-direction: row;
    z-index: 999;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 800px) {
        border-bottom: 1px solid #d4cbca;
    }
`;

export const HeaderWrapper = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d4cbca;
    @media screen and (max-width: 800px) {
        width: 85%;
    }
`;
export const LeftSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > * {
        margin-right: clamp(30px, 5vw, 50px);
    }
`;
export const RightSection = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const FeedBackButtonContainer = styled.div`
    margin-right: 20px;
`;

export const LogoWrapper = styled(Link)`
    width: clamp(122px, 14vw, 140px);
    height: clamp(40px, 5vw, 50px);
`;

export const Logo = styled.img`
    height: 100%;
`;

export const ProfilePicture = styled.div`
    height: clamp(36px, 4.5vw, 54px);
    width: clamp(36px, 4.5vw, 54px);
    object-fit: cover;
    color: #1b4965;
    border-radius: 100%;
    margin-left: clamp(10px, 3vh, 15px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    border: 1px #d4cbca solid;
    &:first-child {
        & path {
            fill: #1b4965;
        }
    }
    &:hover {
        background-color: #1b4965;
        border: 1px transparent solid;
        &:first-child {
            & path {
                fill: white;
            }
        }
    }
`;

export const AvatarRender = styled(AvatarIcon)`
    width: 60%;
    height: 60%;
`;

export const GoBackWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const GoBackContainer = styled.div`
    height: 100%;
    display: flex;
`;

export const GoBackIconContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 33px;
    cursor: pointer;
    &:hover {
        background-color: rgba(27, 73, 101, 0.15);
    }
    transition: all 0.25s ease-in-out;
`;

export const GoBackText = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;

export const DownArrow = styled(BsCaretDownFill)`
    font-size: 10px;
    transform: ${({ upwards }) => (upwards ? "rotate(0)" : "rotate(180deg)")};
    transition: all 0.3s cubic-bezier(0.07, 0.73, 0.72, 0.83);
`;

export const LanguageIcon = styled.div`
    color: #1b4965;
    height: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

export const Globe = styled.img`
    font-size: clamp(10px, 5vw, 20px);
`;

export const LanguageInit = styled.p`
    margin: 8px;
    font-size: clamp(16px, 1vw, 18px);
    font-weight: 400;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
`;

export const LanguageSelectorContainer = styled.div`
    width: 65px;
    height: 102px;
    box-sizing: border-box;
    position: absolute;
    top: ${({ active }) => (active ? "40px" : "20px")};
    left: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 30;
    opacity: ${({ active }) => (active ? "1" : "0")};
    transition: all 0.2s ease;
    pointer-events: ${({ active }) => (active ? "" : "none")};
`;

export const ProfileMenuContainer = styled.div`
    box-sizing: border-box;
    position: absolute;
    top: ${({ active }) =>
        active
            ? "calc( clamp(54px,8vw,84px) - 20px)"
            : "calc( clamp(54px,8vw,84px) - 40px)"};
    pointer-events: ${({ active }) => (active ? "" : "none")};
    padding: 20px 20px 0px 20px;
    right: 0px;
    background-color: #fbf6f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: ${({ active }) => (active ? "1" : "0")};
    border: 0.5px solid #d4cbca;
    border-radius: 5px;
    z-index: 20;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.49, 0.94);
    > * {
        margin-bottom: 20px;
    }
`;
