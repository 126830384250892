import React, { useState, useEffect } from "react";
import axios from "axios";
import DropDown from "../dropDown";
import { FreeInput } from "../inputStyles/style";
import OutsideClickHandler from "react-outside-click-handler";
import { LoadingIcon, SmallText, Text } from "../textStyles/style";
import { useCookies } from "react-cookie";
import {
    ButtonContainer,
    EpisodeContainer,
    SeasonContainer,
    TrailContainer,
    LoadingContainer,
    Question,
    ValidOptWrapper,
    ValidOptItem,
    ValidOpt,
    ValidOptInput,
    RatingContainer,
} from "./style";
import { ButtonPrimary } from "../buttonStyles/style";
import ReactStars from "react-rating-stars-component";
import { FeedBackPopOpener } from "../CustomHooks";
import { useHistory } from "react-router-dom";
import NoTrailSection from "./NoTrailSection";

const TrailSection = ({ name }) => {
    const history = useHistory();

    const cookies = useCookies()[0];
    const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
    const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

    // type states
    const [loader, setLoader] = useState(true);
    const [noSeasonsTrail, setNoSeasonsTrail] = useState(false);
    const [trailOptions, setTrailOptions] = useState([]);
    const [trailDropdown, setTrailDropdown] = useState(false);
    const [selectedTrail, setSelectedTrail] = useState({
        label: "Choisir Trail",
        value: "",
    });

    const [appreciation, setAppreciation] = useState("");
    const [stars, setStars] = useState(2.5);
    const [submitLoader, setSubmitLoader] = useState(false);
    // season states
    const [selectedSeason, setSelectedSeason] = useState({
        label: "Choisir une saison",
        value: "",
    });
    const [seasonDropdown, setSeasonDropdown] = useState(false);
    const [seasonsOptions, setSeasonOptions] = useState([]);
    const [loadingSeasons, setLoaderSeason] = useState(null);
    // episode states
    const [episodeOptions, setEpisodeOptions] = useState([]);
    const [episodeDropdown, setEpisodeDropdown] = useState(false);
    const [selectedEpisode, setSelectedEpisode] = useState({
        label: "Choisir une épisode",
        value: "",
    });

    // freeinputs states
    const [message, setMessage] = useState("");
    const [recommandation, setRecommandation] = useState("");

    const setSeasonHandler = async (selected) => {
        setSelectedEpisode({
            label: "Choisir une épisode",
            value: "",
        });

        setSelectedSeason(selected);
        let t = [];
        await Array.from(Array(selected.numOfEps).keys()).forEach((i) => {
            t.push({
                label: `Episode ${i + 1}`,
                value: (i + 1).toString(),
            });
        });
        setEpisodeOptions(t);
    };

    const getSeasonsHandler = async (selected) => {
        setNoSeasonsTrail(false);
        setLoaderSeason(true);
        setSelectedTrail(selected);
        setSelectedSeason({
            label: "Choisir une saison",
            value: "",
        });
        setEpisodeOptions([]);
        setSelectedEpisode({
            label: "Choisir une épisode",
            value: "",
        });

        axios
            .post(
                process.env.REACT_APP_TRAIL_ENDPOINT +
                    "/" +
                    selected.ressourceCode,
                { access_token: token }
            )
            .then(async (Response) => {
                if (Response.data[0].metadata.seasonsTotal === 0) {
                    setNoSeasonsTrail(true);
                } else {
                    const { content_link } = Response.data[0];
                    let t = [];
                    await Array.from(Array(content_link.length).keys()).forEach(
                        (i) => {
                            t.push({
                                label: `Saison ${i + 1}`,
                                value: (i + 1).toString(),
                                numOfEps: content_link[i].length,
                            });
                        }
                    );
                    setSeasonOptions(t);
                    setTimeout(() => {
                        setLoaderSeason(false);
                    }, 500);
                }
            });
    };

    const submitHandler = () => {
        setSubmitLoader(true);
        axios
            .post(process.env.REACT_APP_FEEDBACK_ENDPOINT, {
                name,
                type: "Trail",
                selectedSeason: selectedSeason.value,
                appreciation,
                selectedEpisode: selectedEpisode.value,
                message,
                note: stars,
                selectedTrail: selectedTrail.value,
                recommandation,
                access_token: token,
            })
            .then(() => {
                setTimeout(() => {
                    setSubmitLoader(false);
                    FeedBackPopOpener(history);
                }, 1000);
            });
    };

    useEffect(async () => {
        let mounted = true;
        if (mounted) {
            setLoader(true);

            axios
                .post(process.env.REACT_APP_TRAIL_ENDPOINT, {
                    access_token: token,
                })
                .then(async (Response) => {
                    let options = [];
                    await Response.data.map((trail) => {
                        options.push({
                            label: trail.ressourceTitle,
                            value: trail.ressourceTitle,
                            ressourceCode: trail.ressourceCode,
                        });
                    });
                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                    setTrailOptions(options);
                });
        }
        return (mounted = false);
    }, [token]);

    if (loader) {
        return (
            <LoadingContainer>
                <LoadingIcon />
            </LoadingContainer>
        );
    }
    return (
        <>
            <TrailContainer>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setTrailDropdown(false);
                    }}
                >
                    <DropDown
                        title="Trails"
                        value={selectedTrail}
                        setValue={getSeasonsHandler}
                        toggle={trailDropdown}
                        setToggle={setTrailDropdown}
                        options={trailOptions}
                    />
                </OutsideClickHandler>
            </TrailContainer>
            {noSeasonsTrail ? (
                <NoTrailSection name={name} selectedTrail={selectedTrail} />
            ) : (
                <>
                    {loadingSeasons ? (
                        <LoadingContainer>
                            <LoadingIcon />
                        </LoadingContainer>
                    ) : (
                        <SeasonContainer>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setSeasonDropdown(false);
                                }}
                            >
                                {seasonsOptions.length > 0 && (
                                    <DropDown
                                        title="Saisons"
                                        value={selectedSeason}
                                        setValue={setSeasonHandler}
                                        toggle={seasonDropdown}
                                        setToggle={setSeasonDropdown}
                                        options={seasonsOptions}
                                    />
                                )}
                            </OutsideClickHandler>
                        </SeasonContainer>
                    )}
                    {episodeOptions.length > 0 && (
                        <>
                            <Question>
                                Avez-vous apprécié cette saison ?
                            </Question>
                            <ValidOptWrapper>
                                <ValidOptItem>
                                    <ValidOptInput
                                        onChange={(e) =>
                                            setAppreciation(e.target.value)
                                        }
                                        type="radio"
                                        id="yes"
                                        value="yes"
                                        checked={appreciation === "yes"}
                                    />
                                    <ValidOpt htmlFor="true">{"Oui"}</ValidOpt>
                                </ValidOptItem>
                                <ValidOptItem>
                                    <ValidOptInput
                                        onChange={(e) =>
                                            setAppreciation(e.target.value)
                                        }
                                        type="radio"
                                        id="no"
                                        value="no"
                                        checked={appreciation === "no"}
                                    />
                                    <ValidOpt htmlFor="atelier">
                                        {"Non"}
                                    </ValidOpt>
                                </ValidOptItem>
                            </ValidOptWrapper>
                        </>
                    )}
                    {episodeOptions.length > 0 && (
                        <EpisodeContainer>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setEpisodeDropdown(false);
                                }}
                            >
                                <DropDown
                                    title="Episodes"
                                    value={selectedEpisode}
                                    setValue={setSelectedEpisode}
                                    toggle={episodeDropdown}
                                    setToggle={setEpisodeDropdown}
                                    options={episodeOptions}
                                />
                            </OutsideClickHandler>
                        </EpisodeContainer>
                    )}
                    {selectedEpisode.value.length === 0 && (
                        <div
                            style={{
                                height: 160,
                                transition: "all 0.3s ease-in-out",
                            }}
                        ></div>
                    )}
                    {selectedEpisode.value.length > 0 && (
                        <>
                            <RatingContainer>
                                <ReactStars
                                    size={45}
                                    isHalf={true}
                                    onChange={setStars}
                                    value={stars}
                                />
                                <SmallText padding="0px 5px" color="#A89D9C">
                                    {stars + " / 5"}
                                </SmallText>
                            </RatingContainer>

                            <FreeInput
                                placeholder="Qu’avez-vous préféré?  💖 ..."
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                }}
                                rows="4"
                            />
                            <FreeInput
                                placeholder="Faites nous une recommandation?  💡 ..."
                                value={recommandation}
                                onChange={(event) => {
                                    setRecommandation(event.target.value);
                                }}
                                rows="4"
                            />
                            <ButtonContainer>
                                <ButtonPrimary
                                    width="100%"
                                    onClick={submitHandler}
                                >
                                    {submitLoader ? (
                                        <LoadingIcon color="inherit" />
                                    ) : (
                                        <Text color="inherit">
                                            Send Feedback
                                        </Text>
                                    )}
                                </ButtonPrimary>
                            </ButtonContainer>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TrailSection;
