import {
    ItemBottomSection,
    ItemTopSection,
    Logo,
    NewWrapper,
    SwiperBackground,
    SwiperItemContainer,
} from "./style";
import { H6, TextBold, TextLabel } from "../textStyles/style";
import WhiteLogo from "../../images/main/white-logo-s.svg";
import { NotAvailablePopOpener } from "../CustomHooks";

const SwiperItem = ({ Type, item, active }) => {
    const checkIfNotAvailable = () => {
        if (item.comingSoon) {
            NotAvailablePopOpener();
        }
    };

    return (
        <SwiperItemContainer
            onClick={checkIfNotAvailable}
            active={active}
            to={
                item.metadata.seasonsTotal === 0
                    ? ""
                    : Type === "Trail"
                    ? "/trail/" + item.ressourceCode
                    : "/workshop/" + item.ressourceCode
            }
        >
            <SwiperBackground src={item.poster_link} />
            <ItemTopSection>
                <Logo src={WhiteLogo} alt="white_logo" />
                <NewWrapper>
                    <TextLabel color="#1B4965">Nouveau</TextLabel>
                </NewWrapper>
            </ItemTopSection>
            <ItemBottomSection>
                <TextBold color="white">{Type}</TextBold>
                <H6 color="white">{item.ressourceTitle}</H6>
            </ItemBottomSection>
        </SwiperItemContainer>
    );
};

export default SwiperItem;
