import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        backend: {
            /* translation file path */
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        fallbackLng: "fr",
        load: "languageOnly",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            useSuspense: true,
        },
        detection: {
            order: [
                "htmlTag",
                "cookie",
                "navigator",
                "subdomain",
                "localStorage",
                "path",
            ],
            caches: ["cookie"],
        },
    });

export default i18n;
