import { Link } from "react-router-dom";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const ButtonPrimary = styled.div`
    ${(props) => {
        if (props.widthfactor) {
            return `
                width : calc( ${props.widthfactor} * clamp(150px,12.5vw,166px) );
            `;
        } else if (props.width) {
            return `
                width : ${props.width};
            `;
        } else {
            return `
                padding:0 30px 0px 30px;
                max-width: fit-content;
            `;
        }
    }}
    box-sizing: border-box;
    height: ${(props) => props.height || "clamp(50px,5.5vw,57px)"};
    text-decoration: none;
    font-size: clamp(15px, 1.5vw, 18px);
    ${(props) => {
        if (props.active_button) {
            if (props.hovercolor) {
                return `
            background-color: ${props.hovercolor};
            color: white;
            `;
            } else {
                return `
            background-color: #1B4965;
            color: white;
            `;
            }
        } else {
            if (props.color) {
                return `
            background-color: ${props.color};
            color: #1b4965;
            `;
            } else {
                return `
            background-color: #88C1A3;
            color: #1b4965;
            `;
            }
        }
    }}
    border-radius: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        height: 55px;
    }
    &:hover {
        color: white;
        background-color: ${(props) => props.hovercolor || "#1B4965"};
    }
    transition: all 0.3s ease-in-out;
`;

export const ButtonPrimaryLink = styled(Link)`
    ${(props) => {
        if (props.widthfactor) {
            return `
                width : calc( ${props.widthfactor} * clamp(150px,12.5vw,166px) );
            `;
        } else if (props.width) {
            return `
                width : ${props.width};
            `;
        } else {
            return `
                padding:0 30px 0px 30px;
                max-width: fit-content;
            `;
        }
    }}
    box-sizing: border-box;
    height: ${(props) => props.height || "clamp(50px,5.5vw,57px)"};
    text-decoration: none;
    font-size: clamp(15px, 1.5vw, 18px);
    ${(props) => {
        if (props.active_button) {
            if (props.hovercolor) {
                return `
            background-color: ${props.hovercolor};
            color: white;
            `;
            } else {
                return `
            background-color: #1B4965;
            color: white;
            `;
            }
        } else {
            if (props.color) {
                return `
            background-color: ${props.color};
            color: #1b4965;
            `;
            } else {
                return `
            background-color: #88C1A3;
            color: #1b4965;
            `;
            }
        }
    }}
    border-radius: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        height: 55px;
    }
    &:hover {
        color: white;
        background-color: ${(props) => props.hovercolor || "#1B4965"};
    }
    transition: all 0.3s ease-in-out;
`;

export const ButtonSecondary = styled.div`
    text-decoration: none;
    background-color: transparent;
    ${(props) => {
        if (props.widthfactor) {
            return `
                width : calc( ${props.widthfactor} * clamp(150px,12.5vw,166px) );
            `;
        } else if (props.width) {
            return `
                width : ${props.width};
            `;
        } else {
            return `
                padding:0 30px 0px 30px;
                max-width: fit-content;
            `;
        }
    }}
    height: ${(props) => props.height || "clamp(50px,5.5vw,57px)"};
    color: #1b4965;
    border-radius: 35px;
    font-size: clamp(15px, 1.5vw, 18px);
    border: 1px solid #d4cbca;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        height: 55px;
    }
    &:hover {
        border-color: ${(props) => props.hovercolor || "#1B4965"};
    }
    transition: all 0.3s ease;
`;

export const ButtonSecondaryLink = styled(Link)`
    text-decoration: none;
    background-color: transparent;
    width: ${(props) => props.width || "100%"};
    height: clamp(50px, 5.5vw, 57px);
    color: #1b4965;
    border-radius: 35px;
    font-size: clamp(15px, 1.5vw, 18px);
    border: 1px solid #d4cbca;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        height: 55px;
    }
    &:hover {
        border-color: ${(props) => props.hovercolor || "#1B4965"};
    }
    transition: all 0.3s ease;
`;

export const ArrowButton = styled.div`
    width: clamp(55px, 6%, 65px);
    height: clamp(55px, 6%, 65px);
    border-radius: 100%;
    border: 1px solid #d4cbca;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    transition: all 0.2s ease;
    &:hover {
        border: 1px solid #1b4965;
    }
    &:active {
        width: clamp(45px, 4%, 55px);
        height: clamp(45px, 4%, 55px);
    }
    @media screen and (max-width: 700px) {
        height: 60px;
        width: 60px;
    }
`;
export const ArrowImg = styled.img`
    width: ${(props) => "calc( " + props.width + " + 5%)" || "20px"};
    height: ${(props) => "calc( " + props.height + " + 5%)" || "20px"};
`;

export const HashButtonPrimary = styled(HashLink)`
    ${(props) => {
        if (props.widthfactor) {
            return `
                width : calc( ${props.widthfactor} * clamp(150px,12.5vw,166px) );
            `;
        } else if (props.width) {
            return `
                width : ${props.width};
            `;
        } else {
            return `
                padding:0 30px 0px 30px;
                max-width: fit-content;
            `;
        }
    }}
    box-sizing: border-box;
    height: ${(props) => props.height || "clamp(50px,5.5vw,57px)"};
    text-decoration: none;
    font-size: clamp(15px, 1.5vw, 18px);
    ${(props) => {
        if (props.active_button) {
            if (props.hovercolor) {
                return `
            background-color: ${props.hovercolor};
            color: white;
            `;
            } else {
                return `
            background-color: #1B4965;
            color: white;
            `;
            }
        } else {
            if (props.color) {
                return `
            background-color: ${props.color};
            color: #1b4965;
            `;
            } else {
                return `
            background-color: #88C1A3;
            color: #1b4965;
            `;
            }
        }
    }}
    border-radius: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 800px) {
        font-size: 18px;
        height: 55px;
    }
    &:hover {
        color: white;
        background-color: ${(props) => props.hovercolor || "#1B4965"};
    }
    transition: all 0.3s ease-in-out;
`;
