import styled from "styled-components";

export const TextInput = styled.input`
    width: 100%;
    position: relative;
    height: 64px;
    border: ${({ isError }) =>
        isError ? "1px solid #FF4E4E" : "1px solid #D4CBCA"};
    box-sizing: border-box;
    background-color: white;
    border-radius: 5px;
    font-size: 18px;
    color: #1b4965;
    padding-left: 5%;
    user-select: text;
    ::placeholder {
        color: #d4cbca;
    }
    &:focus {
        border: 1px solid #88c1a3;
        outline: none;
    }
    &:hover {
        border: 1px solid #88c1a3;
        outline: none;
    }
`;

export const FreeInput = styled.textarea`
    padding: 20px;
    user-select: text;
    resize: none;
    width: 100%;
    position: relative;
    border: ${({ isError }) =>
        isError ? "1px solid #FF4E4E" : "1px solid #D4CBCA"};
    box-sizing: border-box;
    background-color: white;
    border-radius: 5px;
    font-size: 18px;
    padding-left: 15px;
    color: #1b4965;
    line-height: 35px;
    ::placeholder {
        color: #d4cbca;
    }
    &:focus {
        border: 1px solid #88c1a3;
        outline: none;
    }
    &:hover {
        border: 1px solid #88c1a3;
        outline: none;
    }
`;
