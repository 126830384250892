import styled from "styled-components";

export const MsgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: justify;
    width: 100%;
    > * {
        margin-bottom: 10px;
    }
`;
