import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { H5, Text, H6 } from "./textStyles/style";
import UndrawGift from "../images/main/undraw_gift_box.svg";
import GreenLogo from "../images/main/green-logo.svg";
import withReactContent from "sweetalert2-react-content";
import WelcomePopUp from "./welcomePopUp";
import FeedBackPopUp from "./feedback/FeedBackPopUp";

const MySwal = withReactContent(Swal);

export function useSwipeScroll({
  sliderRef,
  reliants = [],
  momentumVelocity = 0.9,
}) {
  const [hasSwiped, setHasSwiped] = useState(false);
  /* eslint-disable */
  const init = useCallback(() => {
    const slider = sliderRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      cancelMomentumTracking();
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
      beginMomentumTracking();
      setTimeout(() => setHasSwiped(false), 0);
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.2; //scroll-speed
      let prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = scrollLeft - walk;
      velX = slider.scrollLeft - prevScrollLeft;
      if (slider.scrollLeft - prevScrollLeft && !hasSwiped) {
        setHasSwiped(true);
      }
    });

    // Momentum
    let velX = 0;
    let momentumID;

    slider.addEventListener("wheel", (e) => {
      cancelMomentumTracking();
    });

    function beginMomentumTracking() {
      cancelMomentumTracking();
      momentumID = requestAnimationFrame(momentumLoop);
    }
    function cancelMomentumTracking() {
      cancelAnimationFrame(momentumID);
    }
    function momentumLoop() {
      slider.scrollLeft += velX;
      velX *= momentumVelocity;
      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
      }
    }
  });
  /* eslint-disable */
  useEffect(() => {
    init();
  }, [...reliants]);

  return {
    hasSwiped,
  };
}

export function useScrollToItem() {
  const path = useHistory().location.hash;
  useEffect(() => {
    let waiting = 750;
    if (!path) {
      waiting = 50;
    }
    const timer = setTimeout(() => {
      let elem = document.getElementById(path.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, waiting);

    return () => {
      clearTimeout(timer);
    };
  }, [path]);
}

export const WelcomePopOpener = () => {
  MySwal.fire({
    title: <H5>Bienvenue Chez Massa Trails Beta</H5>,
    html: <WelcomePopUp />,
    imageUrl: GreenLogo,
    imageWidth: 200,
    imageHeight: 150,
    imageAlt: "Custom image",
    confirmButtonText: <Text>Compris</Text>,
    confirmButtonColor: "#88C1A3",
    confirmButtonAriaLabel: "OK",
    width: "clamp(250px,100%,1300px)",
    padding: "2vw",
    showCloseButton: true,
    showClass: {
      popup: "animate__animated animate__backInDown animate__fast",
    },
    hideClass: {
      popup: "animate__animated animate__backOutDown animate__faster",
    },
  });
};
export const FeedBackPopOpener = (history) => {
  MySwal.fire({
    title: <H5>Merci beaucoup pour votre FeedBack</H5>,
    html: <FeedBackPopUp />,
    imageUrl: UndrawGift,
    imageWidth: 200,
    imageHeight: 150,
    showConfirmButton: false,
    imageAlt: "Custom image",
    timer: 3000,
    timerProgressBar: true,
    width: "clamp(250px,100%,800px)",
    padding: "2vw",
    showCloseButton: true,
    showClass: {
      popup: "animate__animated animate__backInDown animate__fast",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOut animate__faster",
    },
  }).then(() => {
    history.push("/");
  });
};

export const NotAvailablePopOpener = () => {
  MySwal.fire({
    title: <H6>Ce trail ne fait partie du beta test</H6>,
    html: <></>,
    iconColor: "#FD7064",
    icon: "error",
    timer: 3000,
    showConfirmButton: false,
    showCloseButton: true,
    buttonsStyling: false,
    showClass: {
      popup: "animate__animated animate__fadeIn",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOut ",
    },
  });
};
