import { ReactComponent as HeartIcon } from "../../images/main/heart-icon.svg";
import { ReactComponent as LikeIcon } from "../../images/main/like-icon.svg";
import { ReactComponent as PlayIcon } from "../../images/main/play-icon.svg";
import styled from "styled-components";

export const RollContainer = styled.div`
  width: 350px;

  max-height: 0;
  word-wrap: break-word;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  align-self: center;
  padding: 5px;
  padding-left: 10px;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 250px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

export const LikeIconRender = styled(LikeIcon)`
  height: ${({ height }) => height || "40px"};
  width: ${({ width }) => width || "40px"};
  padding: 9px;

  & path {
    fill: ${({ color, isliked }) => (isliked ? "white" : color || "#A89D9C")};
  }
  &:hover {
    fill: white;
    path {
      fill: ${({ hovercolor }) => hovercolor || "white"};
    }
  }
`;

export const HeartIconRender = styled(HeartIcon)`
  height: ${({ height }) => height || "40px"};
  width: ${({ width }) => width || "40px"};

  padding: 9px;
  & path {
    fill: ${({ color, isfavorite }) =>
      isfavorite ? "white" : color || "#A89D9C"};
  }
  &:hover path {
    fill: ${({ hovercolor }) => hovercolor || "white"};
  }
`;
export const PlayIconRender = styled(PlayIcon)`
  height: ${({ height }) => height || "40px"};
  width: ${({ width }) => width || "40px"};
  padding: 12px;
  & path {
    fill: ${({ color }) => color || "white"};
  }
`;

export const PlayIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  background-color: #88c1a3;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &:hover {
    background-color: #1b4965;
  }
`;

export const LikeIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  background-color: ${({ isliked }) => (isliked ? "#94cddb" : "white")};
  border: ${({ isliked }) =>
    isliked ? "1px transparent  solid" : "1px #d4cbca solid"};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &:hover {
    border-color: transparent;
    background-color: ${({ isliked }) => (isliked ? "#94cddb" : "#cae9ff")};
  }
`;

export const FavoriteIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  background-color: ${({ isfavorite }) => (isfavorite ? "#fd7064" : "white")};
  border: ${({ isfavorite }) =>
    isfavorite ? "1px transparent  solid" : "1px #d4cbca solid"};

  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &:hover {
    border-color: transparent;
    background-color: ${({ isfavorite }) =>
      isfavorite ? "#fd7064" : "#fec9b4"};
  }
`;

export const ContentWrapper = styled.div`
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: "border-box";
  position: relative;
  margin-top: 10px;
  text-align: left;
  * {
    margin-top: 10px;
  }
`;

export const SeasonDurationCotnainer = styled.div`
  position: absolute;
  bottom: 0px;
  margin: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
