import styled from "styled-components";
export const FooterContainer = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    position: relative;
`;

export const Copyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    width: 90%;
    border-top: 1px solid #d4cbca;
`;

export const CopyrightText = styled.p`
    font-size: 18px;
    color: #1b4965;
    text-align: center;
    @media screen and (max-width: 700px) {
        font-size: 13px;
    }
`;
