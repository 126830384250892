import styled from "styled-components";
import { BsChevronDown } from "react-icons/bs";

export const ArrowDown = styled(BsChevronDown)`
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 18px;
  margin-right: 5%;
  border-width: 4px solid;

  transform: ${({ toggle }) => (toggle ? "rotate(180deg)" : "rotate(0deg)")};
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.49, 0.94);
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  background-color: white;
  border: 1px #d4cbca solid;
  background-color: white;
  box-sizing: border-box;
  font-size: 18px;
  color: #1b4965;
  border-color: ${({ toggle }) => (toggle ? "#88C1A3" : "#d4cbca")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border-bottom-left-radius: ${({ toggle }) => (toggle ? 0 : 5)};
  border-bottom-right-radius: ${({ toggle }) => (toggle ? 0 : 5)};
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.49, 0.94);
  cursor: pointer;
`;
export const OptionsContainer = styled.div`
  width: calc(100%);
  background-color: white;
  border: 1px solid #88c1a3;
  border-top: none;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 10px 8px;
  position: absolute;
  max-height: ${({ toggle }) => (toggle ? "160px" : "0")};
  opacity: ${({ toggle }) => (toggle ? "1" : "0")};
  transition: max-height 0.3s cubic-bezier(0.17, 0.67, 0.49, 0.94),
    opacity 0.25s ease-in-out;
  overflow: auto;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    background-color: #fbf6f3;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background-color: #fbf6f3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.9),
      0px -3px 20px rgba(0, 0, 0, 0.9);
    background-color: #302b2a;
  }
`;
export const LabelWrapper = styled.div`
  position: absolute;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, white 11.5px, transparent 8.5px);
  box-sizing: border-box;
  padding: 0 10px;
  z-index: 2;
  top: -10px;
  left: calc(5% - 10px);
`;

export const OptionItem = styled.div`
  padding: 10px 5%;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#fbf6f3" : "white")};
  &:hover {
    background-color: #fbf6f3;
  }
`;
export const DropDownWrapper = styled.div`
  width: 100%;
  position: relative;
`;
