import React from "react";
import { Text } from "../textStyles/style";
import { MsgWrapper } from "./style";

const WelcomePopUp = () => {
  return (
    <MsgWrapper>
      <Text>Bonjour,</Text>
      <Text>
        Nos tenons à vous remercier de faire partie de notre communauté de beta
        testeurs.
      </Text>
      <Text>
        Nous nous remercions de bien vouloir nous envoyer un feedback à la fin
        de chaque saison si vous avez décidé de suivre un de nos trails.
      </Text>
      <Text>
        Merci de nous faire un feedback pour chaque atelier que vous suivrez.
      </Text>
      <Text>Le bouton feedback se trouve dans le header.</Text>
      <Text>
        Comme il s’agit d’un beta test, les trails ne sont pas limités. Vous
        pouvez faire plusieurs épisodes le même jour alors que dans la
        plateforme finale, seulement deux épisodes seront accessibles sur une
        même journée. Par ailleurs, à la fin de chaque épisode un atelier
        corporel sera automatiquement proposé. Vous le retrouverez dans les
        ateliers suggérés dans chaque saison. Dans la plateforme finale, ils
        seront directement liés à l’épisode.
      </Text>
    </MsgWrapper>
  );
};

export default WelcomePopUp;
