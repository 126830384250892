import { TextBold, H4, Text } from "../textStyles/style";
import { useState } from "react";
import DropDown from "../dropDown";
import {
  LowerSectionWrapper,
  RowWrapper,
  DropDownContainer,
  HorizontalLine,
  SuggestedWorkshopsContainer,
} from "./style";

import SwiperComponent from "../swiper";

const LowerSection = ({
  data,
  Type = "Trail",
  selectedSeason,
  setSelectedSeason,
  saisons,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LowerSectionWrapper>
      <TextBold style={{ marginBottom: "12px" }}>{Type}</TextBold>
      <RowWrapper>
        <H4>{data.ressourceTitle}</H4>
        <DropDownContainer>
          <DropDown
            title="Saisons"
            toggle={isOpen}
            setToggle={setIsOpen}
            value={selectedSeason}
            setValue={setSelectedSeason}
            options={saisons}
          />
        </DropDownContainer>
      </RowWrapper>

      <Text>
        {data.metadata.description
          ? data.metadata.description
          : "Pas de description"}
      </Text>
      <HorizontalLine />
      <SuggestedWorkshopsContainer>
        <SwiperComponent
          Endpoint={process.env.REACT_APP_WORKSHOP_ENDPOINT}
          SwiperTitle="Ateliers suggérés"
          Type="Atelier"
        />
      </SuggestedWorkshopsContainer>
    </LowerSectionWrapper>
  );
};

export default LowerSection;
