// This file contains the JSX of the non connected header
// This file was last modified on 2022-02-08 16:42:26
// Author : Mohamed Amine Dhiab © Massa Trails

import logo from "../../images/main/green-logo.svg";
import {
    LogoWrapper,
    HeaderSection,
    HeaderWrapper,
    Logo,
    ProfilePicture,
    AvatarRender,
    GoBackText,
    GoBackIconContainer,
    GoBackWrapper,
    GoBackContainer,
    LeftSection,
    RightSection,
    LanguageIcon,
    Globe,
    LanguageInit,
    DownArrow,
    LanguageSelectorContainer,
    ProfileMenuContainer,
    FeedBackButtonContainer,
} from "./style";
import { useLocation, useHistory } from "react-router-dom";
import globe from "../../images/main/globe.svg";
import Icon from "../../images/main/back-arrow.svg";
import { Text, H5 } from "../textStyles/style";
import { useEffect, useState } from "react";
import { ButtonPrimaryLink, ButtonSecondary } from "../buttonStyles/style";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import LanguageSelector from "../languageSelector";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SeeYouImg from "../../images/main/see_you_popup.svg";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import HamburgerMenu from "react-hamburger-menu";
import axios from "axios";

export const DropDownButtonWrapper = styled.div`
    display: none;

    @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
`;

const Header = ({ isOpen, toggle_dropdown }) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [ShowLanguage, setShowLanguage] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [isHome, setIsHome] = useState(true);
    let history = useHistory();
    const path = useLocation().pathname;
    const { i18n } = useTranslation(["header"]);
    const MySwal = withReactContent(Swal);
    const session = cookies[process.env.REACT_APP_COOKIE_NAME];
    const logoutHandler = () => {
        setShowProfileMenu(false);
        MySwal.fire({
            title: <H5>Merci de votre visite</H5>,
            html: <></>,
            imageUrl: SeeYouImg,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 3000,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
                popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp animate__faster",
            },
        }).then(() => {
            axios
                .post(process.env.REACT_APP_LOGOUT_ENDPOINT, {
                    access_token: session ? session : "",
                })
                .then((res) => {
                    console.log(res.status);
                    removeCookie("seen");
                    window.open(process.env.REACT_APP_SIGNIN_URL, "_self");
                })
                .catch((err) => {
                    window.open(process.env.REACT_APP_SIGNIN_URL, "_self");
                });
        });
    };

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsHome(path === "/");
        }
        return (mounted = false);
    }, [path]);
    return (
        <HeaderSection>
            {isHome ? (
                <HeaderWrapper>
                    <LeftSection>
                        <LogoWrapper to="/">
                            <Logo src={logo} alt="image" />
                        </LogoWrapper>
                    </LeftSection>

                    <RightSection>
                        <FeedBackButtonContainer>
                            <ButtonPrimaryLink width="200px" to="/feedback">
                                Feedback
                            </ButtonPrimaryLink>
                        </FeedBackButtonContainer>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                setShowLanguage(false);
                            }}
                        >
                            <LanguageIcon
                                onClick={() => {
                                    setShowLanguage(!ShowLanguage);
                                }}
                            >
                                <Globe src={globe} alt="image" />{" "}
                                <LanguageInit>
                                    {i18n.language === "fr" ? "Fr" : "En"}
                                </LanguageInit>{" "}
                                <DownArrow
                                    upwards={!ShowLanguage ? 1 : 0}
                                    alt="image"
                                />
                                <LanguageSelectorContainer
                                    active={ShowLanguage ? 1 : 0}
                                >
                                    <LanguageSelector
                                        setActive={setShowLanguage}
                                    />
                                </LanguageSelectorContainer>
                            </LanguageIcon>
                        </OutsideClickHandler>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                setShowProfileMenu(false);
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <ProfilePicture
                                    onClick={() =>
                                        setShowProfileMenu(!showProfileMenu)
                                    }
                                >
                                    <AvatarRender />
                                </ProfilePicture>

                                <ProfileMenuContainer active={showProfileMenu}>
                                    <ButtonSecondary
                                        width="200px"
                                        onClick={() =>
                                            setShowProfileMenu(false)
                                        }
                                    >
                                        <AccountBoxIcon
                                            style={{ marginRight: 5 }}
                                            fontSize="small"
                                        />
                                        Mon Compte
                                    </ButtonSecondary>
                                    <ButtonSecondary
                                        width="200px"
                                        onClick={logoutHandler}
                                    >
                                        <LogoutIcon
                                            style={{ marginRight: 5 }}
                                            fontSize="small"
                                        />
                                        Se déconnecter
                                    </ButtonSecondary>
                                </ProfileMenuContainer>
                            </div>
                        </OutsideClickHandler>
                    </RightSection>
                    <DropDownButtonWrapper>
                        <HamburgerMenu
                            isOpen={isOpen}
                            menuClicked={toggle_dropdown}
                            width={25}
                            height={18}
                            strokeWidth={3}
                            rotate={0}
                            color="#1B4965"
                            borderRadius={3}
                            animationDuration={0.4}
                        />
                    </DropDownButtonWrapper>
                </HeaderWrapper>
            ) : (
                <GoBackWrapper>
                    <GoBackContainer>
                        <GoBackIconContainer
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            <img src={Icon} alt="back-img" />
                        </GoBackIconContainer>
                        <GoBackText>
                            <Text>Retour</Text>
                        </GoBackText>
                    </GoBackContainer>
                </GoBackWrapper>
            )}
        </HeaderSection>
    );
};

export default Header;
