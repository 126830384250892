import React from "react";
import styled, { keyframes } from "styled-components";
import { CgSpinnerTwo } from "react-icons/cg";
const LoadingWrapper = styled.div`
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f0ef;
    flex-direction: column;
    border-radius: inherit;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const Spinner = styled(CgSpinnerTwo)`
    width: 50px;
    height: 50px;
    animation: ${rotate} 1s linear infinite;
    color: #1b4965;
`;
const LazyLoading = ({ width, height }) => {
    return (
        <LoadingWrapper width={width} height={height}>
            <Spinner />
        </LoadingWrapper>
    );
};

export default LazyLoading;
