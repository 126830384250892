import React from "react";
import styled, { keyframes } from "styled-components";
import { H2 } from "../textStyles/style";
import { BodyTwo } from "../../globalStyles";
import Smile from "../../images/main/smile.svg";
const changeColor = keyframes`
  0% {
    color: white;
  }
  100% {
    color: #1B4965;
  }
`;
const changeSize = keyframes`
  0% {
    width: 0%;

  }

  100% {
    width: 100%;

  }
`;
const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fbf6f3;
`;
const LoaderContainer = styled.div`
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  animation: ${() => changeColor} 2s;
  animation-direction: alternate;
`;
const SmileImgWrapper = styled.div`
  width: clamp(150px, 70vw, 800px);
  height: clamp(100px, 30vw, 300px);
  position: absolute;
  top: calc(50% + 50px);
  left: calc((100% - clamp(150px, 70vw, 800px)) / 2);
  margin-top: clamp(-50px, -5vw, -25px);
  z-index: 0;
`;
const SmileImgContainer = styled.div`
  width: 100%;
  height: 100%;
  left: calc((100% - clamp(150px, 70vw, 800px)) / 2);
  animation: ${() => changeSize} 2s linear;
  overflow: hidden;
`;
const SmileImg = styled.img`
  width: clamp(150px, 70vw, 800px);
  height: clamp(100px, 30vw, 300px);
`;
const SmileLoader = () => {
  return (
    <LoaderWrapper>
      <BodyTwo />
      <LoaderContainer>
        <H2
          color="inherit"
          stroke="2px #1B4965"
          style={{ zIndex: "1" }}
        >{`Respirez et souriez...`}</H2>
        <SmileImgWrapper>
          <SmileImgContainer>
            <SmileImg src={Smile} />
          </SmileImgContainer>
        </SmileImgWrapper>
      </LoaderContainer>
    </LoaderWrapper>
  );
};

export default SmileLoader;
