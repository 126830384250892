import styled from "styled-components";
import back from "../../images/main/tiles.webp";

export const DropdownContainer = styled.div`
  position: fixed;
  z-index: 998;
  width: 100vw;
  height: calc(100vh - clamp(54px, 8vw, 84px));
  background-color: #fbf6f3;
  display: grid;
  align-items: center;
  left: 0;
  transition: all 0.8s cubic-bezier(0.17, 0.67, 0.36, 0.94);
  opacity: ${({ isopen }) => (isopen ? "1" : "1")};
  top: ${({ isopen }) => (isopen ? "clamp(54px,8vw,84px)" : "-100vh")};

  overflow: hidden;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const DropDownBack = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: url(${back});
  mix-blend-mode: darken;
  opacity: 0.15;
  z-index: -1;
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  box-sizing: border-box;
  > * {
    margin-bottom: 35px;
  }
`;

export const ProfilePicture = styled.div`
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 100%;
  margin-left: clamp(10px, 3vh, 15px);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LanguageSelectorContainer = styled.div`
  width: 65px;
  height: 102px;
  box-sizing: border-box;
  position: fixed;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: all 0.2s ease;
  pointer-events: ${({ active }) => (active ? "" : "none")};
  z-index: 20;
`;
