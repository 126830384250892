import styled from "styled-components";

// Upper section css
export const UpperSectionWrapper = styled.div`
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(27, 73, 101, 0), #1b4965 40%);
    z-index: 1;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    @media screen and (max-width: 550px) {
        max-height: 250px;
    }
`;
export const Poster = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.8;
`;
export const IconImg = styled.img`
    border: ${({ selected }) =>
        selected ? "1px solid #88c1a3" : "1px solid #e1e1e1"};
    padding: 10px;
    border-radius: 20px;
    margin: 20px 0 20px 5px;
    &:hover {
        border: 1px solid #1b4965;
        cursor: pointer;
        box-shadow: 0 1px 20px -8px #1b4965;
        transition: all 0.2s ease-in-out;
    }
`;
export const EpisodesWrapper = styled.div`
    width: 100%;
    display: flex;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 0;
    align-items: center;
    justify-content: ${({ is_enough_episodes }) =>
        is_enough_episodes ? "flex-start" : "center"};
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f4f0ef;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #88c1a3;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #1b4965;
    }
    scroll-margin-top: 50px;
`;

export const EpisodesContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
`;

export const WhiteLine = styled.div`
    width: ${(props) =>
        `calc((100vw - (160px * ${props.episodes_number})) / ${
            props.episodes_number + 1
        })` || "calc((100vw - (160px * 6)) / 7)"};
    min-width: 150px;
    background-color: transparent;
    margin: 0 -5px;
    z-index: 1;
    @media screen and (max-width: 550px) {
        height: 40px;
        min-width: 50px;
        width: ${(props) =>
            `calc((100vw - (75px * ${props.episodes_number})) / ${
                props.episodes_number + 1
            })` || "calc((100vw - (75px * 6)) / 7)"};
    }
`;
export const BorderSvg = styled.img`
    width: 100%;
    min-width: 150px;

    object-fit: cover;
    @media screen and (max-width: 550px) {
        height: 40px;
        min-width: 50px;
    }
    @media screen and (min-width: 3500px) {
        display: none;
    }
`;

export const Episode = styled.div`
    height: 170px;
    width: 170px;
    border-radius: 100%;
    box-sizing: border-box;
    border: 5px solid white;

    opacity: 1;
    z-index: 0;
    cursor: pointer;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    box-sizing: border-box;
    padding: 20px;
    background-color: ${({ isWatched }) =>
        isWatched ? "white" : "transparent"};
    color: ${({ isWatched }) => (isWatched ? "#1b4965" : "white")};
    fill: ${({ isWatched }) => (isWatched ? "#88c1a3" : "white")};
    font-size: 36px;
    &:hover {
        opacity: 1;
        z-index: 2;
        border: 5px solid #1b4965;
        background-color: #1b4965;
        color: #88c1a3;
        fill: #88c1a3;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
    }
    transition: all 0.15s ease-in-out;
    @media screen and (max-width: 550px) {
        height: 85px;
        width: 85px;
        padding: 8px;
        font-size: 14px;
    }
`;
export const PlayButton = styled.div`
    justify-self: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    color: inherit;
    fill: inherit;
    @media screen and (max-width: 550px) {
        height: 23px;
        width: 23px;
    }
`;
export const VideoWrapper = styled.div`
    width: clamp(250px, 78vw, 1600px);
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
        margin-bottom: 20px;
    }
`;
export const VideoContainer = styled.div`
    width: clamp(250px, 100%, 850px);
    height: clamp(200px, 50vw, 480px);
    border-radius: 25px;
    overflow: hidden;
`;

export const VideoTitle = styled.div`
    width: clamp(250px, 100%, 850px);
    display: flex;
    justify-self: center;
    align-items: left;
    text-align: left;
    background: #fbf6f3;
    border-radius: 20px;
    padding: 20px 24px;
    box-sizing: border-box;
`;

// Lower section css
export const LowerSectionWrapper = styled.div`
    width: clamp(250px, 90vw, 1300px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    box-sizing: border-box;
    position: relative;
    padding: 60px 0 40px 0;
    > * {
        margin-bottom: 30px;
    }
    margin-bottom: 550px;
`;
export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #d4cbca;
`;
export const WorkshopsWrapper = styled.div`
    width: 100%;
    overflow: auto;
`;
export const WorkshopsContainer = styled.div`
    width: ${(props) => "calc(400px * " + props.number + ")" || "1000px"};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: ${(props) => "calc(280px * " + props.number + ")" || "1000px"};
    }
`;

export const HeaderSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const SeasonSelect = styled.select`
    width: 200px;
    height: 45px;

    font-weight: "bold";
    padding-left: 10px;
    border: 1px solid #d4cbca;
    cursor: pointer;
    background-color: white;
    align-self: center;
    @media screen and (max-width: 550px) {
        width: 80%;
        margin-top: 10px;
    }
`;

export const DropDownContainer = styled.div`
    width: 390px;
    height: 64px;
    z-index: 999;
    @media screen and (max-width: 550px) {
        width: 90%;
        align-self: center;
        margin-top: 30px;
    }
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        display: flex;

        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
`;

export const MascotteWrapper = styled.div`
    position: absolute;
    right: -30px;
    bottom: -4px;
    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const SuggestedWorkshopsContainer = styled.div`
    position: absolute;
    left: calc((100% - 100vw) / 2);
    width: 100vw;
    top: 100%;
`;
