import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SwiperComponent from "../components/swiper";
import { GlobalContext } from "../context/GlobalState";
import { useCookies } from "react-cookie";
import { H5 } from "../components/textStyles/style";
import LazyLoading from "../components/lazyLoading";

const WelcomeWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - clamp(54px, 8vw, 84px) - 62px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 90px 0;
    z-index: 1;
    > * {
        margin-bottom: 120px;
    }
`;

const Welcome = () => {
    const { setUserInfo } = useContext(GlobalContext);
    const [loader, setLoader] = useState("true");
    const { t } = useTranslation(["welcome"]);
    const cookies = useCookies()[0];
    const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
    const token = process.env.REACT_APP_TOKEN || cookies[cookieName];
    useEffect(() => {
        setLoader(true);
        // GET USER INFO
        axios
            .post(process.env.REACT_APP_USER_INFO_ENDPOINT, {
                access_token: token,
            })
            .then((res) => {
                setUserInfo(res.data);
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                setLoader(false);
            });
    }, [token]);

    if (loader) {
        return (
            <LazyLoading height="calc(100vh - clamp(54px, 8vw, 84px) - 62px)" />
        );
    }

    return (
        <WelcomeWrapper>
            <SwiperComponent
                Endpoint={process.env.REACT_APP_TRAIL_ENDPOINT}
                SwiperTitle="Trails"
                Type="Trail"
            />
            <SwiperComponent
                Endpoint={process.env.REACT_APP_WORKSHOP_ENDPOINT}
                SwiperTitle={t("workshops")}
                Type="Atelier"
            />
        </WelcomeWrapper>
    );
};

export default Welcome;
