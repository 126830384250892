import React from "react";
import { Text } from "../textStyles/style";
import { MsgWrapper } from "./style";
const FeedBackPopUp = () => {
  return (
    <MsgWrapper>
      <Text>
        Merci pour votre engagement dans cette première phase de test.
      </Text>
      <Text>
        Toutes vos remarques seront prises en compte pour améliorer notre offre.
      </Text>
      <Text>L’équipe de MASSA Trails vous remercie de votre confiance</Text>
    </MsgWrapper>
  );
};

export default FeedBackPopUp;
