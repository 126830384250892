import React from "react";
import { ArrowButton, ArrowImg } from "./style";
import arrowLeft from "../../images/main/arrow_left.svg";
import arrowRight from "../../images/main/arrow_right.svg";
const ArrowComponent = ({ ClickFunction, ArrowDirection, Width, Height }) => {
    var ArrowIcon = arrowLeft;
    if (ArrowDirection === "right") {
        ArrowIcon = arrowRight;
    }
    return (
        <ArrowButton onClick={ClickFunction}>
            <ArrowImg
                src={ArrowIcon}
                width={Width}
                height={Height}
                alt="image"
            />
        </ArrowButton>
    );
};

export default ArrowComponent;
