import {
    H5,
    Text,
    H4,
    SmallBoldText,
    TextBold,
    H7,
    H6,
} from "../textStyles/style";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Icon } from "../../images/main/play-arrow.svg";
import wave from "../../images/main/border-white.svg";
import Mascotte from "../../images/main/mascotte.svg";
import Surprised from "../../images/main/surprised.svg";
import Happy from "../../images/main/happy.svg";
import Inlove from "../../images/main/in-love.svg";
import VideoJS from "../VideoJS";
import "animate.css";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ButtonPrimary } from "../buttonStyles/style";
import {
    UpperSectionWrapper,
    Poster,
    MascotteWrapper,
    EpisodesWrapper,
    EpisodesContainer,
    WhiteLine,
    BorderSvg,
    Episode,
    PlayButton,
    VideoWrapper,
    VideoContainer,
    VideoTitle,
    IconImg,
} from "./style";
import { Box } from "@mui/material";
import { GlobalContext } from "../../context/GlobalState";
import EndVideoPopUp from "../EndVideoPopUp";
import { useCookies } from "react-cookie";

const MySwal = withReactContent(Swal);

const UpperSection = ({ data, selectedSeason }) => {
    const { userInfo, setUserInfo } = useContext(GlobalContext);
    const cookies = useCookies()[0];
    const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
    const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

    const watchedEpisodes = userInfo.watched_episodes;

    const EpisodesNumber = data.content_link[selectedSeason.value].length;
    const playerRef = useRef(null);
    const handlePlayerReady = (movement, player, episodeCode) => {
        playerRef.current = player;
        // you can handle player events here
        player.on("waiting", () => {
            console.log("player is waiting");
        });

        player.on("play", () => {
            console.log("player will dispose");
        });
        // Tracking finished video and update watchedEpisodes Array
        player.on("ended", () => {
            axios
                .post(process.env.REACT_APP_TRACKING_ENDPOINT, {
                    access_token: token,
                    episodeCode,
                })
                .then(() => {
                    axios
                        .post(process.env.REACT_APP_USER_INFO_ENDPOINT, {
                            access_token: token,
                        })
                        .then((res) => {
                            setUserInfo(res.data);
                            localStorage.setItem(
                                "userInfo",
                                JSON.stringify(res.data)
                            );
                            if (!movement) {
                                alertEndVideo(episodeCode);
                            }
                        });
                });
        });
    };
    const handlePlayerMovmentReady = (player, episodeCode) => {
        playerRef.current = player;

        // you can handle player events here
        player.on("waiting", () => {
            console.log("player is waiting");
        });

        player.on("play", () => {
            console.log("player will dispose");
        });
        player.on("ended", () => {
            alertEndVideo(episodeCode);
        });
    };
    const alertEndVideo = (episodeCode) => {
        MySwal.fire({
            title: <H5>Bravo !</H5>,
            html: (
                <EndVideoPopUp
                    close={() => MySwal.close()}
                    episodeCode={episodeCode}
                />
            ),
            showConfirmButton: false,

            showCloseButton: true,
            footer: (
                <MascotteWrapper>
                    <img src={Mascotte} width={200} height={140} />
                </MascotteWrapper>
            ),
            showClass: {
                popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp animate__faster",
            },
            width: "clamp(250px,90vw,1000px)",
        }).then(() => {});
    };
    const openNewAlert = useCallback((src, episodeCode) => {
        MySwal.fire({
            html: (
                <VideoWrapper>
                    <VideoContainer>
                        <VideoJS
                            src={src}
                            onReady={(props) =>
                                handlePlayerMovmentReady(props, episodeCode)
                            }
                            type={
                                src.split(".")[1] == "mp3"
                                    ? "audio/mp3"
                                    : "application/x-mpegURL"
                            }
                        />
                    </VideoContainer>
                </VideoWrapper>
            ),
            confirmButtonText: <Text color="#1B4965">Fermer la vidéo</Text>,
            confirmButtonColor: "#88C1A3",
            confirmButtonAriaLabel: "OK",
            showCloseButton: true,
            width: "auto",
            showClass: {
                popup: "animate__animated animate__backInDown animate_faster",
            },
            hideClass: {
                popup: "animate__animated animate__backOutDown animate__faster",
            },
        }).then(() => {});
    }, []);
    const handleClick = useCallback((Title, src, MovementData, episodeCode) => {
        MySwal.fire({
            html: (
                <VideoWrapper>
                    <VideoTitle>
                        <H5>{Title}</H5>
                    </VideoTitle>
                    <VideoContainer>
                        <VideoJS
                            src={src}
                            onReady={(player) =>
                                handlePlayerReady(
                                    MovementData,
                                    player,
                                    episodeCode
                                )
                            }
                            type={
                                src.split(".")[src.split(".").length - 1] ==
                                "mp3"
                                    ? "audio/mp3"
                                    : "application/x-mpegURL"
                            }
                        />
                    </VideoContainer>
                </VideoWrapper>
            ),
            showConfirmButton: false,
            showCloseButton: true,
            footer: MovementData ? (
                <VideoTitle
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <H7>Mouvement corporel</H7>
                    <ButtonPrimary
                        onClick={() =>
                            openNewAlert(MovementData.link, episodeCode)
                        }
                    >
                        Commencer la vidéo
                    </ButtonPrimary>
                </VideoTitle>
            ) : (
                <></>
            ),
            width: "auto",
            showClass: {
                popup: "animate__animated animate__backInDown animate_faster",
            },
            hideClass: {
                popup: "animate__animated animate__backOutDown animate__faster",
            },
        }).then(() => {});
    }, []);
    return (
        <UpperSectionWrapper>
            <Poster src={data.poster_link} alt="poster" />
            <EpisodesWrapper is_enough_episodes={EpisodesNumber > 1}>
                <EpisodesContainer>
                    {EpisodesNumber > 1 && (
                        <WhiteLine episodes_number={EpisodesNumber} order={1}>
                            <BorderSvg src={wave} alt="" />
                        </WhiteLine>
                    )}
                    {data.content_link[selectedSeason.value].map(
                        (item, index) => {
                            // check if watched or not
                            const isWatched = watchedEpisodes.includes(
                                item.episodeCode
                            );
                            return (
                                <EpisodesContainer key={index}>
                                    <Episode
                                        isWatched={isWatched ? 1 : 0}
                                        onClick={() => {
                                            handleClick(
                                                data.ressourceTitle,
                                                item.link,
                                                item.movement,
                                                item.episodeCode
                                            );
                                        }}
                                    >
                                        <H5 color="inherit" fontsize="inherit">
                                            Ép. {index + 1}
                                        </H5>
                                        <PlayButton>
                                            <Icon
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </PlayButton>
                                    </Episode>
                                    {EpisodesNumber > 1 && (
                                        <WhiteLine
                                            episodes_number={EpisodesNumber}
                                            order={index % 2}
                                        >
                                            <BorderSvg src={wave} alt="" />
                                        </WhiteLine>
                                    )}
                                </EpisodesContainer>
                            );
                        }
                    )}
                </EpisodesContainer>
            </EpisodesWrapper>
        </UpperSectionWrapper>
    );
};

export default UpperSection;
