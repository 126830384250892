import { Box } from "@mui/system";
import React, { useState } from "react";
import styled from "styled-components";
import { H6, SmallBoldText } from "./textStyles/style";
import Surprised from "../images/main/surprised.svg";
import Happy from "../images/main/happy.svg";
import Inlove from "../images/main/in-love.svg";
import { ButtonPrimary } from "./buttonStyles/style";
import axios from "axios";
import { useCookies } from "react-cookie";

const IconImg = styled.img`
  border: ${({ selected }) =>
    selected ? "1px solid #88c1a3" : "1px solid #e1e1e1"};
  padding: 10px;
  border-radius: 20px;
  margin: 20px 0 20px 5px;
  &:hover {
    border: 1px solid #88c1a3;
    cursor: pointer;
    box-shadow: 0 1px 20px -8px #88c1a3;
    transition: all 0.2s ease-in-out;
  }
`;

const EndVideoPopUp = ({ episodeCode, close }) => {
  const [feedback, setFeedback] = useState("");
  const cookies = useCookies()[0];
  const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
  const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

  const sendFeedBackHandler = () => {
    if (feedback !== "") {
      axios
        .post(process.env.REACT_APP_PREFERENCES_ENDPOINT, {
          access_token: token,
          episodeCode,
          feedback,
        })
        .then((res) => {
          close();
        });
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <SmallBoldText style={{ marginBottom: 20 }}>
        Vous avez terminé l’épisode {episodeCode.split("-")[3].substring(1)}
      </SmallBoldText>
      <H6>Avez-vous apprécié cet épisode ?</H6>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <IconImg
          onClick={() => {
            setFeedback("bad");
          }}
          selected={feedback === "bad" ? 1 : 0}
          src={Surprised}
          width={90}
          height={40}
        />

        <IconImg
          onClick={() => {
            setFeedback("good");
          }}
          selected={feedback === "good" ? 1 : 0}
          src={Happy}
          width={90}
          height={40}
        />

        <IconImg
          onClick={() => {
            setFeedback("excellent");
          }}
          selected={feedback === "excellent" ? 1 : 0}
          src={Inlove}
          width={90}
          height={40}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <ButtonPrimary onClick={sendFeedBackHandler}>Confirmer</ButtonPrimary>
      </div>
    </Box>
  );
};

export default EndVideoPopUp;
