import React from "react";
import { useState } from "react";
import { H6, H5, TextBold, H4 } from "../components/textStyles/style";
import { TextInput } from "../components/inputStyles/style";
import TrailSection from "../components/feedback/TrailSection";
import WorkshopSection from "../components/feedback/WorkshopSection";
import {
    Question,
    ValidOpt,
    ValidOptItem,
    ValidOptWrapper,
    FeedbackContainer,
    FeedbackWrapper,
    ValidOptInput,
} from "../components/feedback/style";

const Feedback = () => {
    const { name, surname } = JSON.parse(localStorage.getItem("userInfo"));
    const [selectedType, setSelectedType] = useState("");

    const fullName = name + " " + surname;

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    return (
        <FeedbackWrapper>
            <FeedbackContainer>
                <H4 style={{ textAlign: "center" }}>Partagez votre Feedback</H4>
                <H6 style={{ fontWeight: "bold" }}>
                    Bonjour{" "}
                    <H6
                        style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                    >
                        {fullName}
                    </H6>{" "}
                    et Bienvenue sur{" "}
                    <H6 style={{ fontWeight: "bold" }} color="#88C1A3">
                        Massa Trails
                    </H6>{" "}
                    😃 !
                </H6>

                <Question>Choisissez une option</Question>
                <ValidOptWrapper>
                    <ValidOptItem>
                        <ValidOptInput
                            onChange={handleTypeChange}
                            type="radio"
                            id="trail"
                            value="trail"
                            checked={selectedType === "trail"}
                        />
                        <ValidOpt htmlFor="trail">{"Trail"}</ValidOpt>
                    </ValidOptItem>
                    <ValidOptItem>
                        <ValidOptInput
                            onChange={handleTypeChange}
                            type="radio"
                            id="atelier"
                            value="atelier"
                            checked={selectedType === "atelier"}
                        />
                        <ValidOpt htmlFor="atelier">{"Atelier"}</ValidOpt>
                    </ValidOptItem>
                </ValidOptWrapper>
                {selectedType === "" ? (
                    <></>
                ) : selectedType === "trail" ? (
                    <TrailSection name={name} />
                ) : (
                    <WorkshopSection name={name} />
                )}
            </FeedbackContainer>
        </FeedbackWrapper>
    );
};

export default Feedback;
