import { useEffect, useState } from "react";
import styled from "styled-components";
import UpperSection from "../components/trail/upperSection";
import { useParams } from "react-router-dom";
import LazyLoading from "../components/lazyLoading";
import axios from "axios";
import LowerSection from "../components/trail/lowerSection";
import { useCookies } from "react-cookie";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const TrailWrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
`;
const Trail = () => {
    const [Loader, setLoader] = useState(true);
    const [data, setData] = useState({});
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState({
        label: "Saison 1",
        value: 0,
    });
    const cookies = useCookies()[0];
    const { id } = useParams();
    const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
    const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

    useEffect(async () => {
        let mounted = true;
        if (mounted) {
            setLoader(true);
            const payload = { access_token: token };
            const Response = await axios.post(
                process.env.REACT_APP_TRAIL_ENDPOINT + "/" + id,
                payload
            );
            setData(Response.data[0]);
            // Create an array of season with label and value = [{value:0, label: 'Saison 1'}, ....]
            let s = [];
              
            Array.from(
                Array(Response.data[0].content_link.length).keys()
            ).forEach((i) => {
                s.push({ label: `Saison ${i + 1}`, value: i });
            });

            setSeasons(s);
            setLoader(false);
        }
        return (mounted = false);
    }, [id, token]);

    return (
        <div>
            {Loader ? (
                <TrailWrapper>
                    <LazyLoading height={Height} />
                </TrailWrapper>
            ) : (
                <TrailWrapper>
                    <UpperSection data={data} selectedSeason={selectedSeason} />
                    <LowerSection
                        saisons={seasons}
                        data={data}
                        selectedSeason={selectedSeason}
                        setSelectedSeason={setSelectedSeason}
                    />
                </TrailWrapper>
            )}
        </div>
    );
};

export default Trail;
