import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import LazyLoading from "../components/lazyLoading";
import axios from "axios";
import VideoJS from "../components/VideoJS";
import { useCookies } from "react-cookie";
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const WorkshopWrapper = styled.div`
    width: 100vw;
    min-height: ${Height};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 55px 0;
`;

const VideoWrapper = styled.div`
    height: clamp(200px, 35vw, 800px);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    @media screen and (min-width: 801px) {
        border-radius: 25px;
    }
    @media screen and (max-width: 800px) {
        width: clamp(250px, 100vw, 1300px);
        aspect-ratio: 16 / 9;
        height: auto;
    }
`;

const Workshop = () => {
    const playerRef = useRef(null);
    const [Loader, setLoader] = useState(true);
    const [data, setData] = useState({});
    const { id } = useParams();
    const cookies = useCookies()[0];
    const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
    const token = process.env.REACT_APP_TOKEN || cookies[cookieName];
    useEffect(async () => {
        let mounted = true;
        if (mounted) {
            const payload = { access_token: token };
            setLoader(true);
            const Response = await axios.post(
                process.env.REACT_APP_WORKSHOP_ENDPOINT + "/" + id,
                payload
            );
            setData(Response.data[0]);
            setLoader(false);
        }
        return (mounted = false);
    }, [id, token]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on("waiting", () => {
            console.log("player is waiting");
        });

        player.on("play", () => {
            console.log("player will dispose");
        });
    };
    return (
        <WorkshopWrapper>
            <VideoWrapper style={{ zIndex: "1", cursor: "pointer" }}>
                {Loader ? (
                    <LazyLoading />
                ) : data.content_link[0].link.split(".")[
                      data.content_link[0].link.split(".").length - 1
                  ] == "mp4" ? (
                    <video
                        width="100%"
                        height="100%"
                        controls
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <source
                            src={data.content_link[0].link}
                            type="video/mp4"
                        ></source>
                    </video>
                ) : (
                    <VideoJS
                        src={data.content_link[0].link}
                        onReady={handlePlayerReady}
                        type={
                            data.content_link[0].link.split(".")[
                                data.content_link[0].link.split(".").length - 1
                            ] == "mp3"
                                ? "audio/mp3"
                                : "application/x-mpegURL"
                        }
                    />
                )}
            </VideoWrapper>
        </WorkshopWrapper>
    );
};

export default Workshop;
