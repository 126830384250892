import GlobalStyles, {
    AppWrapper,
    BodyFooterWrapper,
    BodyOne,
    BodyTwo,
} from "./globalStyles";
import "./i18n";
import { Route } from "react-router-dom";
import Header from "./components/header";
import DropdownMenu from "./components/header/DropdownMenu";
import React, { useEffect, Suspense, useState, useContext } from "react";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/footer";
// import LazyLoading from "./components/lazyLoading";
import Welcome from "./pages/welcome";
import Trail from "./pages/trail";
import Workshop from "./pages/workshop";
import { useCookies } from "react-cookie";
import Feedback from "./pages/feedback";
import { WelcomePopOpener } from "./components/CustomHooks";
import axios from "axios";
import SmileLoader from "./components/smileLoader";
import { GlobalProvider } from "./context/GlobalState";

function App() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [ShowApp, setShowApp] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const toggle_dropdown = () => {
        setIsOpen(!isOpen);
    };
    const seen = cookies.seen;
    const session = cookies[process.env.REACT_APP_COOKIE_NAME];

    useEffect(() => {
        if (parseInt(process.env.REACT_APP_IS_PROD)) {
            axios
                .post(process.env.REACT_APP_AUTH_ENDPOINT, {
                    access_token: session ? session : "",
                })
                .then((res) => {
                    console.log(res.status);
                    setShowApp(true);
                    setTimeout(() => {
                        setLoader(false);
                        if (loader && seen === undefined) {
                            WelcomePopOpener();
                            setCookie("seen", true);
                        }
                    }, 4000);
                })
                .catch((err) => {
                    window.open(process.env.REACT_APP_SIGNIN_URL, "_self");
                });
        } else {
            setShowApp(true);
            setTimeout(() => {
                setLoader(false);
                if (loader && seen === undefined) {
                    WelcomePopOpener();
                    setCookie("seen", true);
                }
            }, 4000);
        }
    }, []);

    if (loader && ShowApp) {
        return <SmileLoader />;
    }
    return (
        <>
            {ShowApp ? (
                <GlobalProvider>
                    <AppWrapper>
                        <GlobalStyles />
                        <ScrollToTop />
                        <Suspense fallback={null}>
                            <Header
                                isOpen={isOpen}
                                toggle_dropdown={toggle_dropdown}
                            />
                            <Suspense fallback={null}>
                                <DropdownMenu
                                    isOpen={isOpen}
                                    toggle_dropdown={toggle_dropdown}
                                />
                            </Suspense>
                            <BodyFooterWrapper>
                                <BodyOne>
                                    <BodyTwo />
                                    <Route exact path="/">
                                        <Welcome />
                                    </Route>
                                    <Route exact path="/trail/:id">
                                        <Trail />
                                    </Route>
                                    <Route exact path="/workshop/:id">
                                        <Workshop />
                                    </Route>
                                    <Route exact path="/feedback">
                                        <Feedback />
                                    </Route>
                                </BodyOne>
                                <Footer />
                            </BodyFooterWrapper>
                        </Suspense>
                    </AppWrapper>
                </GlobalProvider>
            ) : (
                <></>
            )}
        </>
    );
}

export default App;
