import { useEffect, useState } from "react";
import {
  Content,
  ContentWrapper,
  SwiperItemContainer,
  SwiperWrapper,
  TitleWrapper,
  RollOverWrapper,
} from "./style";
import { H6, TextBold } from "../textStyles/style";
import axios from "axios";
import LazyLoading from "../lazyLoading";
import SwiperItem from "./SwiperItem";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import RollOver from "../rollover";
import ArrowComponent from "../buttonStyles/ArrowComponent";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
// import required modules
import { Keyboard, Navigation } from "swiper";

const SwiperComponent = ({
  Endpoint,
  SwiperTitle = "Trails",
  Type = "Trail",
}) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const cookies = useCookies()[0];
  const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
  const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

  const { t } = useTranslation(["welcome"]);
  const [active, setActive] = useState(null);
  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      setLoader(true);

      const Response = await axios.post(Endpoint, { access_token: token });

      setData(Response.data);
      setLoader(false);
    }
    return (mounted = false);
  }, [Endpoint, token]);

  // Data.forEach((d) => {
  //   console.log(d.ressourceTitle, ":", d.metadata.seasonsTotal);
  // });

  return (
    <SwiperWrapper isontop={Type === "Trail" ? 1 : 0}>
      <TitleWrapper>
        <H6 fontsize="24px">{SwiperTitle}</H6>

        <TextBold color="#D4CBCA">{t("see_all")}</TextBold>
      </TitleWrapper>
      <ContentWrapper>
        {loader ? (
          <>
            <SwiperItemContainer to="/">
              <LazyLoading />
            </SwiperItemContainer>
          </>
        ) : (
          <Content>
            <Swiper
              spaceBetween={30}
              slidesPerView={"auto"}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              keyboard={{
                enabled: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Keyboard, Navigation]}
            >
              {Data.map((item, index) => {
                const isActive = index === active ? 1 : 0;
                return (
                  <SwiperSlide
                    key={index}
                    onMouseEnter={() => setActive(index)}
                    onMouseLeave={() => setActive(null)}
                  >
                    <SwiperItem Type={Type} item={item} active={isActive} />

                    <RollOverWrapper active={isActive}>
                      <RollOver Type={Type} item={item} />
                    </RollOverWrapper>
                  </SwiperSlide>
                );
              })}
              <div className="swiper-button-next">
                <ArrowComponent direction="right" Width="16px" Height="7px" />
              </div>
              <div className="swiper-button-prev">
                <ArrowComponent direction="left" Width="16px" Height="7px" />
              </div>
            </Swiper>
          </Content>
        )}
      </ContentWrapper>
    </SwiperWrapper>
  );
};

export default SwiperComponent;
