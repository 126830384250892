import React, { useState } from "react";

import { ButtonPrimaryLink, ButtonSecondary } from "../buttonStyles/style";
import globe from "../../images/main/globe.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import OutsideClickHandler from "react-outside-click-handler";
import { DownArrow, Globe, LanguageIcon, LanguageInit } from "./style";
import {
  DropdownContainer,
  DropDownBack,
  DropDownWrapper,
  LanguageSelectorContainer,
} from "./dropstyle";
import { Text, H5 } from "../textStyles/style";
import SeeYouImg from "../../images/main/see_you_popup.svg";

import i18n from "../../i18n";
import LanguageSelector from "../languageSelector";
import { useCookies } from "react-cookie";

const DropdownMenu = ({ isOpen, toggle_dropdown }) => {
  const MySwal = withReactContent(Swal);
  const [ShowLanguage, setShowLanguage] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const logoutHandler = () => {
    toggle_dropdown();
    MySwal.fire({
      title: <H5>Merci de votre visite</H5>,
      html: <></>,
      imageUrl: SeeYouImg,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: "Custom image",
      timer: 3000,
      showConfirmButton: false,

      timerProgressBar: true,
      padding: "2vw",
      showCloseButton: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__fast",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then(() => {
      removeCookie("session");
      removeCookie("seen");
      window.open(process.env.REACT_APP_SIGNIN_URL, "_self");
    });
  };

  return (
    <DropdownContainer isopen={isOpen}>
      <DropDownBack />
      <DropDownWrapper>
        <ButtonSecondary onClick={toggle_dropdown} width="300px">
          <AccountBoxIcon style={{ marginRight: 5 }} fontSize="small" />
          Mon Compte
        </ButtonSecondary>
        <ButtonSecondary width="300px" onClick={logoutHandler}>
          <LogoutIcon style={{ marginRight: 5 }} fontSize="small" />
          Se déconnecter
        </ButtonSecondary>
        <ButtonPrimaryLink
          onClick={toggle_dropdown}
          width="300px"
          to="/feedback"
        >
          Feedback
        </ButtonPrimaryLink>

        <OutsideClickHandler
          onOutsideClick={() => {
            setShowLanguage(false);
          }}
        >
          <LanguageIcon
            onClick={() => {
              setShowLanguage(!ShowLanguage);
            }}
          >
            <Globe src={globe} alt="image" />{" "}
            <LanguageInit>{i18n.language === "fr" ? "Fr" : "En"}</LanguageInit>{" "}
            <DownArrow upwards={!ShowLanguage ? 1 : 0} alt="image" />
          </LanguageIcon>
          <LanguageSelectorContainer active={ShowLanguage ? 1 : 0}>
            <LanguageSelector setActive={setShowLanguage} />
          </LanguageSelectorContainer>
        </OutsideClickHandler>
      </DropDownWrapper>
    </DropdownContainer>
  );
};

export default DropdownMenu;
