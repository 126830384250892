import { Copyright, FooterContainer, CopyrightText } from "./style";

const Footer = () => {
    return (
        <FooterContainer>
            <Copyright>
                <CopyrightText>
                    Copyright © MASSA TRAILS 2022. Tous droits réservés.
                </CopyrightText>
            </Copyright>
        </FooterContainer>
    );
};

export default Footer;
