import React, { useState, useEffect } from "react";
import axios from "axios";
import DropDown from "../dropDown";
import { FreeInput } from "../inputStyles/style";
import {
  Container,
  ButtonContainer,
  LoadingContainer,
  RatingContainer,
} from "./style";
import { ButtonPrimary } from "../buttonStyles/style";
import { LoadingIcon, Text, SmallText } from "../textStyles/style";
import ReactStars from "react-rating-stars-component";
import { FeedBackPopOpener } from "../CustomHooks";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import OutsideClickHandler from "react-outside-click-handler";

const WorkshopSection = ({ name }) => {
  const cookies = useCookies()[0];
  const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
  const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [workshopOptions, setWorkshopOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [stars, setStars] = useState(2.5);
  const [workshopDropdown, setWorkshopDropdown] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedWorkShop, setSelectedWorkshop] = useState({
    label: "Choisir Atelier",
    value: "",
  });

  const submitHandler = () => {
    setSubmitLoader(true);

    // POST /feedback
    axios
      .post(process.env.REACT_APP_FEEDBACK_ENDPOINT, {
        type: "workshop",
        selected: selectedWorkShop.value,
        message,
        name,
        note: stars,
        access_token: token,
      })
      .then(() => {
        setTimeout(() => {
          setSubmitLoader(false);
          FeedBackPopOpener(history);
        }, 1000);
      });
  };

  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      setLoader(true);

      axios
        .post(process.env.REACT_APP_WORKSHOP_ENDPOINT, { access_token: token })
        .then(async (Response) => {
          let options = [];
          await Response.data.map((workshop) => {
            options.push({
              label: workshop.ressourceTitle,
              value: workshop.ressourceTitle,
            });
          });
          setTimeout(() => {
            setLoader(false);
          }, 500);
          setWorkshopOptions(options);
        });
    }
    return (mounted = false);
  }, [token]);

  if (loader) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  }
  return (
    <>
      <Container>
        <OutsideClickHandler
          onOutsideClick={() => {
            setWorkshopDropdown(false);
          }}
        >
          <DropDown
            title="Ateliers"
            value={selectedWorkShop}
            setValue={setSelectedWorkshop}
            toggle={workshopDropdown}
            setToggle={setWorkshopDropdown}
            options={workshopOptions}
          />
        </OutsideClickHandler>
      </Container>

      {selectedWorkShop.value.length > 0 && (
        <div style={{ display: "grid" }}>
          <RatingContainer style={{ marginBottom: 20 }}>
            <ReactStars
              size={45}
              isHalf={true}
              onChange={setStars}
              value={stars}
            />
            <SmallText padding="0px 5px" color="#A89D9C">
              {stars + " / 5"}
            </SmallText>
          </RatingContainer>
          <FreeInput
            placeholder="Votre Feedback nous aide à améliorer Massa Trails 😃 ..."
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            rows="6"
          />
          <ButtonContainer>
            <ButtonPrimary width="100%" onClick={submitHandler}>
              {submitLoader ? (
                <LoadingIcon color="inherit" />
              ) : (
                <Text color="inherit">Send Feedback</Text>
              )}
            </ButtonPrimary>
          </ButtonContainer>
        </div>
      )}
    </>
  );
};

export default WorkshopSection;
