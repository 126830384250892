import React, { useState } from "react";
import { ButtonPrimary } from "../buttonStyles/style";
import { FreeInput } from "../inputStyles/style";
import { LoadingIcon, Text, H6 } from "../textStyles/style";
import { ButtonContainer } from "./style";
import { useHistory } from "react-router-dom";
import { FeedBackPopOpener } from "../CustomHooks";
import axios from "axios";
import { useCookies } from "react-cookie";

const NoTrailSection = ({ name, selectedTrail }) => {
  const [recommandation, setRecommandation] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const history = useHistory();

  const cookies = useCookies()[0];
  const cookieName = process.env.REACT_APP_COOKIE_NAME || "session";
  const token = process.env.REACT_APP_TOKEN || cookies[cookieName];

  const submitHandler = () => {
    setSubmitLoader(true);
    axios
      .post(process.env.REACT_APP_FEEDBACK_ENDPOINT, {
        name,
        type: "Trail",
        selectedTrail: selectedTrail.value,
        recommandation,
        access_token: token,
      })
      .then(() => {
        setTimeout(() => {
          setSubmitLoader(false);
          FeedBackPopOpener(history);
        }, 1000);
      });
  };

  return (
    <>
      <H6 fontsize="clamp(16px,1.5vw,22px)" style={{ textAlign: "center" }}>
        Ce trail arrivera bientôt !
      </H6>
      <FreeInput
        placeholder="Faites nous une recommandation?  💡 ..."
        value={recommandation}
        onChange={(event) => {
          setRecommandation(event.target.value);
        }}
        rows="4"
      />
      <ButtonContainer>
        <ButtonPrimary width="100%" onClick={submitHandler}>
          {submitLoader ? (
            <LoadingIcon color="inherit" />
          ) : (
            <Text color="inherit">Send Feedback</Text>
          )}
        </ButtonPrimary>
      </ButtonContainer>
    </>
  );
};

export default NoTrailSection;
