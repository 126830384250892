import styled from "styled-components";
import greenCircleTick from "../../images/main/green_circle_tick.svg";
export const Container = styled.div`
    z-index: 5;
`;

export const TrailContainer = styled.div`
    z-index: 20;
`;
export const SeasonContainer = styled.div`
    z-index: 10;
`;
export const EpisodeContainer = styled.div`
    z-index: 5;
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 200px;
    justify-self: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 200px;
    justify-self: center;
`;

export const SubmitButton = styled.button`
    width: 50%;
    border: none;
    background-color: #88c1a3;
    height: 40px;
    color: white;
    font-size: clamp(14px, 1.3vw, 18px);
    font-family: "Oxygen";
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #1b4965;
    }
`;

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

export const FeedbackWrapper = styled.div`
    width: 100vw;
    min-height: ${Height};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
`;

export const FeedbackContainer = styled.div`
    width: clamp(250px, 95vw, 700px);
    height: auto;
    padding: clamp(10px, 5vw, 30px);
    box-sizing: border-box;
    border-radius: 20px;
    display: grid;
    grid-gap: 20px;
    z-index: 1;
`;

export const ValidOptWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
export const ValidOptItem = styled.div`
    height: 100%;

    display: flex;
    justify-content: left;
    align-items: flex-start;
    @media screen and (max-width: 500px) {
        width: 50%;
    }
`;
export const ValidOptInput = styled.input`
    -webkit-appearance: none;
    border: 2px solid #d4cbca;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
    }
    &:checked:before {
        width: 20px;
        height: 20px;
        transition: all 0.1s ease-in-out;
        background-image: url(${greenCircleTick});
        background-position: center;
        background-repeat: no-repeat;
    }
    &:checked {
        border: none;
    }
    &:hover {
        box-shadow: 0 0 5px 0px #d4cbca inset;
        border: 2px solid #88c1a3;
    }
`;

export const ValidOpt = styled.label`
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    font-family: "Oxygen";
    color: #1b4965;
    margin-left: 5px;
`;
export const Question = styled.span`
    font-size: 18px;
    font-weight: 700;
    color: #1b4965;
`;

export const RatingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
`;

export const MsgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding-bottom: 20px;
    > * {
        margin-bottom: 10px;
    }
`;
